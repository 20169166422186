
import Vue from "vue";
import FileUploader from "@/components/form-components/FileUploader.vue";
import API from "@/api/API";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";
import MultiplePhonePicker from "@/components/form-components/MultiplePhonePicker.vue";

export default Vue.extend({
  name: "Create",

  components: {
    MultiplePhonePicker,
    FileUploader
  },

  data: () => ({
    rules,
    model: {
      name: "" as string,
      full_name: "" as string,
      login: "" as string,
      password: "" as string,
      email: "" as string,
      password_confirmation: "" as string,
      commission: "" as string,
      position_id: null as any,
      phones: [],
      roles: [] as Array<any>
    } as any,
    items: [] as any,
    errorMessages: {} as any
  }),

  computed: {
    user() {
      return this.$store.getters["user/info"];
    },
    required() {
      return (
        this.model.roles.length > 0 || this.$t("global_validation.required")
      );
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const item = await API.users().getForEdit(to.params.id);
      const roles = await API.roles().getList();

      next(vm => {
        vm.model = item;
        vm.items = roles;

        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async getForEdit(): Promise<void> {
      try {
        this.items = await this.$API.roles().getList();
        const response = await this.$API
          .users()
          .getForEdit(this.$route.params.id);
        this.model = { ...response, phones: response.phones?.split(",") };
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          const model = { ...this.model };
          const [position] = model.roles;
          model.position_id = position;

          await this.$API.users().edit(this.$route.params.id, model);
          if (this.user.id === this.model.id) {
            await this.$store.dispatch("user/set", this.user);
          }
          await this.$router.push("/users");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
