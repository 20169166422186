var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height d-flex justify-center"},[_c('div',{staticClass:"content full-width"},[_c('div',{staticClass:"text-h5 mb-4 primary--text"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("users.form.header.edit"))+" ")])]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"elevation-4 pa-4",attrs:{"width":"100%"}},[_c('v-card-text',{staticClass:"pa-5 pb-0"},[_c('v-form',{ref:"form",staticClass:"d-flex flex-column justify-center",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',{staticClass:"align-self-center pt-6 mx-2"},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","sm":"12","md":"6","lg":"4","xl":"4"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-account","rules":[
                    _vm.rules.required('name'),
                    _vm.rules.min(3),
                    _vm.rules.max(100)
                  ],"error-messages":_vm.errorMessages.name,"label":((_vm.$t('users.form.fields.name')) + "*")},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","sm":"12","md":"6","lg":"4","xl":"4"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-email","rules":[
                    _vm.rules.required('email'),
                    _vm.rules.min(10),
                    _vm.rules.max(255),
                    _vm.rules.email
                  ],"error-messages":_vm.errorMessages.email,"label":((_vm.$t('users.form.fields.email')) + "*")},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","sm":"12","md":"6","lg":"4","xl":"4"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-account-multiple-outline","items":_vm.items,"multiple":"","rules":[_vm.rules.required('position') ].concat( _vm.required),"error-messages":_vm.errorMessages.password_confirmation,"label":((_vm.$t('users.form.fields.role')) + "*")},model:{value:(_vm.model.roles),callback:function ($$v) {_vm.$set(_vm.model, "roles", $$v)},expression:"model.roles"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"4"}},[_c('file-uploader',{attrs:{"label":_vm.$t('users.form.fields.avatar'),"error-messages":_vm.errorMessages.avatar,"accept":"image/png, image/jpeg, image/bmp"},model:{value:(_vm.model.avatar),callback:function ($$v) {_vm.$set(_vm.model, "avatar", $$v)},expression:"model.avatar"}})],1)],1),_c('multiple-phone-picker',{attrs:{"rules":[
                _vm.rules.required('phones'),
                _vm.rules.min(8),
                _vm.rules.max(255)
              ],"label":((_vm.$t('users.form.fields.phone')) + "*"),"error-messages":_vm.errorMessages.phones},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}},model:{value:(_vm.model.phones),callback:function ($$v) {_vm.$set(_vm.model, "phones", $$v)},expression:"model.phones"}}),_c('v-row',{staticClass:"actions"},[_c('v-col',{staticClass:"pa-2 d-flex align-center"},[_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("global_validation.is_required")))]),_c('v-spacer'),_c('v-btn',{attrs:{"link":"","to":"/users","text":""}},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.cancel"))+" ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","depressed":""},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.confirm"))+" ")])],1)],1)],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }